
import searchModel from './search/searchModel';
import html from './objects/html';

const SearchModel = searchModel.SearchModel;

function render(container, data, searchModel, el, hideWhenEmpty) {

	if (data.isLoading) {
		container.style.opacity = '0.5';
		// don't render when loading
		return false;
	} else {
		container.style.opacity = '';
	}

	// empty container
	html.empty(container);

	// get vars
	const jobs = data.response.data || [];
	const pagination = !data.isLoading && data.response.pagination || false;
	const jobCount = !data.isLoading && pagination && pagination.totalItems || 0;

	const selectedJobFamily = searchModel.getSelectedJobFamilies()[0] || {};
	const selectedJobFamilyName = selectedJobFamily.Singular_Name || selectedJobFamily.Name || "";

	const roleText = selectedJobFamily.Role_Text && selectedJobFamily.Role_Text.toLowerCase() || "role";

	const selectedLocation = searchModel.getSelectedLocations()[0] || {};
	const selectedLocationName = selectedLocation.Name || "";

	const jobFamilyText = data.jobFamily.length ? ` ${selectedJobFamilyName} ` : '';
	const locationText = data.location.length ? ` in ${selectedLocationName}` : '';

	const empty = `
		<h2>We currently have no open ${jobFamilyText} roles${locationText}.</h2>
		<p>But keep checking back later – new roles are added every day.</p>
		<div class="margin-top-2">
			<a href="${data.searchBaseUrl}" class="a-button a-button--primary">View all roles</a>
		</div>
	`;

	if (!jobs.length && hideWhenEmpty) {
		el.style.display = 'none'
	} else {
		el.style.display = 'block'
	}

	// get job template
	const jobsComponent = jobs.map(job => renderJob(job)).join('');

	const s = jobCount === 1 ? '' : 's';
	const buttonText = (jobCount <= 6) ? `View all ${jobFamilyText} ${roleText}s${locationText}` : `View all ${jobCount} ${jobFamilyText} ${roleText}${s}${locationText}`;

	const justifyCentreClass = jobCount === 1 ? 'flex-justify-content-center' : '';

	const main = `
		<!-- jobs container -->
		<div class="l-columns l-columns--2-large l-columns--tight ${justifyCentreClass} u-margin--top-none">${jobsComponent}</div>
		<div class="margin-top-2">
			<a href="${data.searchUrl}" class="a-button a-button--primary">${buttonText}</a>
		</div>
	`;

	container.appendChild(jobs.length ? html.parse(main) : html.parse(empty));	
}


function renderJob(job) {
	const jobFamilies = job.Virtual_Job_Family_Names_Array.join(', ');
	const locations = job.Virtual_Location_Names_Array.join(', ');
	return `
		<a target="_blank" rel="noopener" href="${job.External_Job_Path}" class="l-columns__column m-card t-accent-light--white u-content-fade text-left">
			<div class="m-card-content m-card-content--alt-1">
				<div class="m-card-content__inner m-card-content__inner--top">
					<h3 class="a-heading a-heading--2 a-heading--semibold a-heading--blue">${job.Job_Posting_Title}</h3>
				</div>
				<div class="m-card-content__inner m-card-content__inner--bottom">
					<p class="m-card-tagline m-card-tagline--multi">
						<span class="m-card-tagline__inner m-card-tagline__primary">${jobFamilies}</span>
						<span class="m-card-tagline__inner m-card-tagline__secondary">${locations}</span>
						<span class="m-card-tagline__inner m-card-tagline__tertiary">${job.Job_Posting_Start_Date_Timeago}</span>
					</p>
				</div>
			</div>
		</a>
	`;
}


export const initInstance = function(el) {

	const container = el.querySelector('.js-latestVacancies__container'); 
	const jobFamily = el.querySelector('.js-latestVacancies__jobFamily');
	const location = el.querySelector('.js-latestVacancies__location');
	const search = el.querySelector('.js-latestVacancies__search');
	const jobType = el.querySelector('.js-latestVacancies__jobType');
	const timeType = el.querySelector('.js-latestVacancies__timeType');
	const FILTERS = JSON.parse(el.getAttribute('data-filters'));
	const hideWhenEmpty = JSON.parse(el.getAttribute('data-hide-when-empty'))

	const searchModel = new SearchModel({
		fields : { 
			jobFamily, 
			location,
			search,
			jobType,
			timeType,
		},
		initialData : {
			perPage: 6
		},
		searchUrlBlacklist : ['perPage'],
		FILTERS : FILTERS
	});
	
	searchModel.onChange(data => {
		render(container, data, searchModel, el, hideWhenEmpty)
	});

	// run initial to stop page caching affecting job listings
	searchModel.getJobs();
}
